import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Breadcrumb from "../components/Global/Breadcrumb"

import User from "./../images/user.svg"
import { el } from 'date-fns/locale'
import Calendar from "./../images/calendar.svg"

// import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'
import ImageViewer from "react-simple-image-viewer";
import promoBanner from "../images//mpesstodiktio-banner-1-300x300.png"

// import "../components/Blog/PostTemplate.css"


// import {
//     EmailShareButton,
//     FacebookShareButton,
//     WhatsappShareButton,
//     FacebookIcon,
//     WhatsappIcon,
//     EmailIcon
// } from "react-share";


function BlogPostTemplate(props) {
    let data = props.data;

    let availableVersionsFiltered = props.pageContext.availablePages

    if (availableVersionsFiltered[0]) {
        let tmp = `${availableVersionsFiltered[0].language.slug === "en" ? `/${availableVersionsFiltered[0].language.slug}` : ""}/blog/${availableVersionsFiltered[0].slug}`

        availableVersionsFiltered[0].uri = tmp;
    }

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([])

    const [customOpener, setCustomOpener] = useState("");

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    useEffect(() => {
        const isBrowser = typeof window !== "undefined";


        if (isBrowser) {
            if (data.allWpPost.nodes[0].extraGallery?.gallery && data.allWpPost.nodes[0].extraGallery?.gallery.length == 0) {
                var elements = document.getElementsByClassName("blocks-gallery-item");

                var myFunction = function (e) {

                    if (e.target.src) {
                        setCustomOpener(e.target.src)
                    } else {
                        console.log("NO SOURCE")
                    }

                    // console.log(e.target.src);
                    // console.log(e.target.currentSrc)
                    // console.log(images.indexOf(e.target.currentSrc))

                };

                let tmpGallery = [];
                for (var i = 0; i < elements.length; i++) {

                    elements[i].addEventListener('click', myFunction, false);

                    // console.log("!!!!!!!!!!!!!!!")

                    // console.log(elements[i].children[0].children[0].children[2])
                    // console.log(elements[i])
                    // console.log(elements[i]?.children[0]?.children[0]?.children[0]?.children[2])
                    if (elements[i]?.children[0]?.children[0]?.children[0]?.children[2]?.children[1]?.src) {
                        tmpGallery.push(elements[i]?.children[0]?.children[0]?.children[0]?.children[2]?.children[1]?.src)
                    }
                }

                console.log(tmpGallery)
                setImages(tmpGallery);
            }else{
                console.log("koda")
                let tmpGallery = [];

                if(data.allWpPost.nodes[0].extraGallery?.gallery){
                    data.allWpPost.nodes[0].extraGallery?.gallery.map((img3) => {
                        tmpGallery.push(img3.image.sourceUrl)
                    })
                }
                setImages(tmpGallery);
            }
        }


        // if (isBrowser) {
        //     let content = document.querySelector("#post-editor");
        //     var div = document.createElement('div');

        //     let lc = document.querySelector("#post-editor2");

        //     if (data.allWpPost.nodes[0].content) {

        //         div.innerHTML = data.allWpPost.nodes[0].content.trim();
        //         // console.log(div)
        //         // console.log(div.children[0])
        //         let i = 0;
        //         for (i = 0; i < div.children.length; i++) {

        //             if (div.children[i].innerText.includes("btn_link=") && div.children[i].innerText.includes("vc_video")) {
        //                 let p = document.createElement("p");

        //                 p.innerText = div.children[i].innerText;
        //                 div.children[i].innerText = "";
        //                 div.children[i].appendChild(p);
        //             }


        //             if (div.children[i].innerText.includes("btn_link=")) {
        //                 console.log(div.children[i].innerText)

        //                 var myLink = div.children[i].innerText.substring(
        //                     div.children[i].innerText.indexOf("btn_link=”url:") + 14,
        //                     div.children[i].innerText.lastIndexOf("|||”")
        //                 );

        //                 var myString = div.children[i].innerText.match(/(?<=btn_title=”\s*).*?(?=\s*” )/gs);


        //                 let a = document.createElement("a");

        //                 a.href = decodeURIComponent(myLink);
        //                 a.innerText = myString[0];

        //                 a.className = "js-generated-bttns"

        //                 div.children[i].appendChild(a);

        //             }
        //             if (div.children[i].innerText.includes("vc_video")) {
        //                 var result = div.children[i].innerText.match(/(?<=vc_video link=”\s*).*?(?=\s*” )/gs);
        //                 let p = document.createElement("iframe");
        //                 p.src = "https://www.youtube.com/embed/" + result[0].replace("https://youtu.be/", "");
        //                 p.width = "100%"
        //                 p.height = "407px"
        //                 div.children[i].appendChild(p);
        //             }

        //             if (div.children[i].innerText.includes("btn_link=") && div.children[i].innerText.includes("vc_video")) {
        //                 div.children[i].children[0].innerText = "";
        //                 console.log(div.children[i])
        //             }


        //             if (div.children[i].innerText.includes("css=”.vc_custom_")) {
        //                 div.children[i].innerText = ""
        //                 div.children[i].style.display = "none";
        //             }

        //             if (div.children[i].innerText === "[/vc_column_text][vc_column_text]") {
        //                 div.children[i].innerText = ""
        //                 div.children[i].style.display = "none";
        //             }

        //         }
        //         content.innerHTML = "";
        //         lc.appendChild(div)

        //         // console.log(content)
        //     }

        // }

    }, [])

    useEffect(() => {
        if (customOpener !== "") {
            // console.log("$$$$$$$$$$$$$$$$$$$$$$$")
            // console.log(images)
            // console.log(customOpener)
            // console.log(images.indexOf(customOpener))

            openImageViewer(images.indexOf(customOpener))
        }
    }, [customOpener])


    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);

    };


    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            recentPosts={data.related}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={availableVersionsFiltered}
            metaData={constructMetaData(data.allWpPost.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
        >
            <section>
                <Breadcrumb h1={""} />
            </section>

            <article className="container mx-auto mt-16 px-4 flex flex-wrap" style={{ maxWidth: "1170px" }}>
                <div className="w-fullpb-0 lg:pb-8">
                    <div className="sp-img-container w-full relative" >
                        {(data.allWpPost.nodes[0] && data.allWpPost.nodes[0].featuredImage && data.allWpPost.nodes[0].featuredImage.node) && (
                            // <GatsbyImage
                            //     image={data.allWpPost.nodes[0].featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                            //     alt={data.allWpPost.nodes[0].featuredImage.node.altText}
                            //     className=" w-full pb-6 sp-f-img" />
                            <img src={data.allWpPost.nodes[0].featuredImage.node.sourceUrl} alt={data.allWpPost.nodes[0].featuredImage.node.altText} />
                        )}
                    </div>
                    <div>
                        <h1 className="bread-h1" style={{ lineHeight: "1.6" }}>{data.allWpPost.nodes[0].title}</h1>
                    </div>
                    {/* <div className="flex blog-info py-6 mb-4" style={{ borderBottom: "1px solid rgba(0,0,0,0.08)" }}>
                        <div className="flex justify-center">
                            <div>
                                <img src={Calendar} style={{ width: "15px", marginRight: "5px" }} />
                            </div>
                            <div>{format(new Date(data.allWpPost.nodes[0].date), 'dd MMMM, yyyy', { locale: el })}</div>
                        </div>
                    </div> */}
                    <div>
                        <div className="sp-content-wrapper">
                            <div id="post-editor" dangerouslySetInnerHTML={{ __html: data.allWpPost.nodes[0].content }} />
                            {/* <div id="post-editor2" /> */}

                            {data.allWpPost.nodes[0].extraGallery?.gallery && data.allWpPost.nodes[0].extraGallery?.gallery.length > 0 && (
                                <ul className="flex flex-wrap pt-4 pb-8">
                                    {data.allWpPost.nodes[0].extraGallery?.gallery.map((img2, i) => (
                                        <li className="w-full md:w-1/2 lg:w-1/3 pr-4 py-2" key={`img-gallery-key-${i}`}>
                                            {img2?.image?.sourceUrl && (
                                                <img
                                                    onClick={(e) => setCustomOpener(e.target.src)}
                                                    src={img2.image.sourceUrl} 
                                                    alt={img2.image.altText} 
                                                    style={{ borderRadius: "5px", cursor: "zoom-in" }}
                                                />
                                            )}
                                            
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>
                    </div>
                </div>

                {/* <div className="w-full lg:w-1/3 pl-0 lg:pl-8 py-8 lg:py-0">
                    <div className="relative">
                        <h3 className="side-cat-h3">
                            {props.pageContext.language === "EL" ? "Σελίδα Facebook" : "Facebook Page"}
                        </h3>
                        <iframe name="f3ba4c7232c5028" width="1000px" height="1000px" data-testid="fb:page Facebook Social Plugin" title="fb:page Facebook Social Plugin" frameborder="0" allowtransparency="true" allowFullScreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.8/plugins/page.php?adapt_container_width=true&amp;app_id=409125935834772&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1eb9cfd9332814%26domain%3Dwww.dkaa.gr%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.dkaa.gr%252Ff38930436ea6bd8%26relation%3Dparent.parent&amp;container_width=360&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fdkaa.gr&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=" style={{ border: "none", visibility: "visible", width: "340px", height: "500px" }} class="" data-uw-styling-context="true"></iframe>

                    </div>
                    <div className="mt-8">
                        <Link to={props.pageContext.language === "EL" ? "/become-member" : "/en/become-member-en"}>
                            <img src={promoBanner} />
                        </Link>
                    </div>

                </div> */}
            </article>

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                />
            )}
        </Layout>
    );
}

export default BlogPostTemplate


export const pageQuery = graphql`query BLOG_POST_QUERY($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq:"cG9zdDo1MDYz"}}) {
        nodes{
            breadcrumbAcf {
            breadcrumbName
    
            }
        }
    }


    allWpPost(filter: {id:{eq: $id }} ) {
        nodes {
            slug
            title
            content
            date
            author {
                node {
                name
                firstName
                avatar {
                    url
                }
                }
            }
            seo {
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                sourceUrl
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    altText
                }
            }

            extraGallery {
                gallery {
                    image {
                        sourceUrl
                        altText
                    }
                }
            }
        }
      }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    related: allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
